<template>
  <div>
    <el-card :body-style="{ padding: '10px'}">
      <div slot="header">
        <div class="shaixuan">
          <div style="display: flex;">
            <div class="inp">
              <el-input v-model="keywords" size="medium" clearable placeholder="请输入名称" @change="filterData"/>
            </div>
            <div class="inp">
              <el-autocomplete v-model="supplier" size="medium" :fetch-suggestions="querySearch" clearable placeholder="请输入供货商" @select="filterData" @change="filterData"/>
            </div>
            <div class="inp">
              <el-select v-model="industry" size="medium" placeholder="请选择品类" clearable @change="filterData">
                <el-option
                  v-for="item in industry_list"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <el-date-picker
              @change="filterData"
              size="medium"
              v-model="times"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              value-format="yyyy-MM-dd"
              end-placeholder="结束日期">
            </el-date-picker>
            <el-button type="primary" size="medium" style="margin-left: 10px" @click="filterData">搜索</el-button>
          </div>
          <div>
            <el-button type="primary" size="medium" @click="compute">计算选中</el-button>
            <el-button type="success" size="medium" @click="exportExcel">导出选中</el-button>
          </div>
          
        </div>
      </div>
      <el-table :data="tableData" size="medium" stripe v-loading="loading" @selection-change="handleSelectionChange" :row-class-name="checkStyle" height="730" element-loading-text="拼命加载中" @sort-change="sortChange">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column width="55" type="index" label="序号" align="center"></el-table-column>
        <el-table-column prop="title"  align="center" label="名称" sortable="custom" min-width="150"></el-table-column>
        <el-table-column prop="supplier_title"  align="center" sortable="custom" label="供货商"></el-table-column>
        <el-table-column prop="industry_1"  align="center" sortable="custom" label="品类"></el-table-column>
        <el-table-column prop="total_claims"  align="center" sortable="custom" label="理赔金额"></el-table-column>
        <el-table-column prop="total_claims_count"  align="center" sortable="custom" label="理赔次数"></el-table-column>
        <el-table-column prop="total_yfx"  align="center" sortable="custom" label="投保份数">
        </el-table-column>
        <el-table-column prop="total_yfx_count"  align="center" sortable="custom" label="投保订单数"></el-table-column>
        <el-table-column prop="claims_ratio"  align="center" sortable="custom" label="理赔率"></el-table-column>
      </el-table>
      <div class="page">
        <BasePagination :total="total" :now.sync="currentPage" :pageSize="pageSize" @changeSize="changeSize"></BasePagination>
      </div>
      
      <el-dialog title="选中项监控数据" :visible.sync="dialogVisible" width="500px" @close="dialogVisible = false">
        <div class="dialog">
          <span :class="red">理赔总金额：{{item.total_claims}}</span>
          <span>理赔总份数：{{item.total_claims_count}}</span>
          <span>投保总份数：{{item.total_yfx}}</span>
          <span>投保总订单数：{{item.total_yfx_count}}</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
    
  </div>
</template>

<script>
import { export_json_to_excel } from "@/plugins/Export2Excel";
import { getFirthDay, getPreMonthDay } from "@/utils/utils";
export default {
  props: {
    msg: String
  },
  data () {
    return {
      currentPage: 1,
      pageSize: 50,
      total: 0,
      tableData: [],
      loading: false,

      multipleSelection: [],
      dialogVisible: false,

      times: [],

      item: {},
      sortItem: {},
      keywords: '',
      supplier_list: [],
      supplier: '',
      industry_list: [],
      industry: ''
    }
  },
  computed: {
    red() {
      let flag = this.jine - this.lipeiPrice < 0
      return {
        [this.$style.red]: flag
      }
    }
  },
  watch: {
    currentPage() {
      this.getData()
    }
  },
  mounted () {
    this.times = [getFirthDay(), getPreMonthDay()]
    this.getData()
  },
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.supplier_list;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      console.log(results);
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    sortChange(column) {
      console.log(column);
      switch (column.order) {
        case 'descending':
          this.sortItem.order_type = 'desc'
          break;
      case 'ascending':
          this.sortItem.order_type = 'asc'
          break;
      }
      this.sortItem.order_field = column.prop
      this.filterData()
    },
    changeSize(val) {
      this.pageSize = val
      this.filterData()
    },
    exit() {
      // this.$confirm('确定要退出登录吗?', '提示').then(() => {
      //   this.$emit('changeLogin', true)
      //   localStorage.setItem('login', false)
      // })
    },
    checkStyle({row}) {
      if(row.isRed) {
        return this.$style.red
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    // 导出选中
    exportExcel() {        
      if(!this.multipleSelection.length)
        return this.$message.warning('请至少选中一条数据')  
      const excelHeader = ["名称", "供货商", "品类", "理赔金额", "理赔次数", "投保份数", "投保订单数", "理赔率"]
      const filterVal = ["title", "supplier_title", "industry_1", "total_claims", "total_claims_count", "total_yfx", "total_yfx_count", "claims_ratio"]
      const data = this.formatJson(filterVal, this.multipleSelection);
      const excelName='团品列表监控数据'
      export_json_to_excel(excelHeader, data, excelName)

    },
    // 计算选中
    compute() {
      if(!this.multipleSelection.length)
        return this.$message.warning('请至少选中一条数据')
      this.item = {
        total_yfx_count: 0,
        total_yfx: 0,
        total_claims_count: 0,
        total_claims: 0,
      }
      this.multipleSelection.map(o => {
        console.log(o);
        this.item.total_yfx_count += o.total_yfx_count,
        this.item.total_yfx += o.total_yfx,
        this.item.total_claims_count += o.total_claims_count,
        this.item.total_claims += o.total_claims
      })
      this.item.total_yfx = this.item.total_yfx.toFixed(2)
      // this.item.total_claims = this.item.total_claims.toFixed(2)
      this.dialogVisible = true
    },
    filterData() {
      this.currentPage = 1
      this.getData()
    },
    getData() {
      this.loading = true
      this.$http.get('/insured_order_data_product', {
        params: {
          title: this.keywords,
          page_number: this.pageSize,
          page: this.currentPage,
          start_time: this.times?.[0],
          end_time: this.times?.[1],
          supplier: this.supplier,
          industry: this.industry,
          ...this.sortItem
        }
      }).then(res => {
        this.loading = false
        this.tableData = res.data.data.map(o => {
          if(o.total_claims > o.total_yfx)o.isRed = true
          return o
        })
        
        this.supplier_list = res.data.supplier_list.map(o => {
          return {value: o}
        })
        this.industry_list = res.data.industry_list
        this.total = res.data.total
        // console.log('industry', this.supplier_list)
      }).catch(() => {
        this.tableData = []
        this.loading = false
      })
    }
  }
}
</script>

<style module>
.red {
  color: red !important;
  font-weight: bold;
}
</style>
<style lang="scss" scoped>
.hello {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.title {
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}
.right {
  /* position: absolute;
  right: 0px;
  top: 0px; */
  text-align: right;
  font-size: 16px;
}
.hello span {
  margin-right: 30px;
}
.page {
  padding-top: 10px;
  text-align: center;
}
.dialog {
  width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: bold;
  line-height: 36px;
}
.shaixuan {
  /* margin: 0 20px; */
  display: flex;
  justify-content: space-between;
  .inp {
    margin-right: 10px;
  }
}
</style>
